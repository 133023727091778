import React from 'react';

function Title(){

    const text = "Award Winning";

    const letters = [...text].map((letter, key) => {
        if(letter === ' '){
            letter = '';      
        }
        return (
            <span key={key} className="c-title__letter" data-letter={letter}>{letter}</span>
        );
    });

    return (<>
        <h1 className="c-title">
            {letters}
        </h1>
    </>);
}

export default Title;