import React from 'react';

function Stars(){

  const getRandom = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;

  let stars = [];   
  for(let i = 0; i < 600; i++ ){
    stars[i] = <rect 
      className={"c-stars__star" + (i % 2 ? ' c-stars__star--blink' : '')} 
      key={i} 
      x={Math.round(getRandom(0,1)*screenWidth)} 
      y={Math.round(getRandom(0,1)*screenHeight)}
      style={{
        // background: 'red'
        animationDuration: getRandom(1,3) + "s",
        animationDelay: getRandom(1,3) + "s"
      }} 
    />
  }

  return <>
      <svg className="c-stars" viewBox={"0 0 " + screenWidth + " " + screenHeight} >
        {stars}
      </svg>
    </>;
}

export default Stars;