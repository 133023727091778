import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import './scss/main.scss';

import Title from './components/Title.js';
import Stars from './components/Stars.js';
import Fireworks from './components/Fireworks.js';
import audio from "./assets/awardwinning.mp3";

function App() {
  const [active, setActive] = useState(false);
  const audioPlayer = new Audio(audio);

  useEffect(() => {
    if(active){
      audioPlayer.loop = true;
      audioPlayer.play();
    }
  }, [active, audioPlayer]);

  const componentClass = classNames({
    'app': true,
    'app--active': active,
  });

  return (
    <div className={componentClass}
      onClick={() => {
        setActive(true);
      }} 
    >
      <Stars />
      <Fireworks />
      <Title />
    </div>
  );
}

export default App;
